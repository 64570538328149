import React, { useEffect, useMemo, useReducer, useState } from "react";
import { SimpleReveal } from "simple-reveal";

import ImgMissionTitle from "!!raw-loader!../assets/img_mission_title.svg";

import { GetMissionStatusResponse, useAjax } from "../ajax";
import ImgMissionGuide1 from "../assets/img_mission_guide_1.svg";
import ImgMissionGuide2 from "../assets/img_mission_guide_2.svg";
import ImgMissionGuide3 from "../assets/img_mission_guide_3.svg";
import ImgMissionTada from "../assets/img_mission_tada.png";
import { karrotBridge, useBridgeData } from "../bridge";
import * as css from "./Mission.css";

// eslint-disable-next-line no-shadow
enum LocalStorageKeys {
  HasStartMissionButtonClicked = "has_start_mission_button_clicked",
}

const TAB_HOME_HREF =
  process.env.GATSBY_STAGE === "production"
    ? "karrot://tab/home"
    : "karrot.alpha://tab/home";

function usePersistedState() {
  const [hasStartMissionButtonClicked, setHasStartMissionButtonClicked] =
    useState<boolean | null>(null);

  useEffect(() => {
    setHasStartMissionButtonClicked(
      localStorage.getItem(LocalStorageKeys.HasStartMissionButtonClicked) !==
        null,
    );
  }, []);

  return useMemo(
    () => ({
      hasStartMissionButtonClicked,
    }),
    [hasStartMissionButtonClicked],
  );
}

const Mission: React.FC = () => {
  const bridgeData = useBridgeData();
  const ajax = useAjax();
  const { hasStartMissionButtonClicked } = usePersistedState();

  const [missionStatus, setMissionStatus] =
    useState<GetMissionStatusResponse | null>(null);

  useEffect(() => {
    if (!ajax) {
      return;
    }

    (async () => {
      const [error, resp] = await ajax.getMissionStatus();

      if (error) {
        return;
      }

      setMissionStatus(resp);
    })();
  }, [ajax, setMissionStatus]);

  const isMissionCompleted =
    hasStartMissionButtonClicked !== null &&
    missionStatus?.user_mission_result.has_written_free_sales_article &&
    missionStatus?.user_mission_result.has_written_review &&
    missionStatus?.user_mission_result.has_written_sales_article;

  return (
    <div className={css.container}>
      <div className={css.tadaContainer}>
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <img
              ref={ref}
              className={cn(css.tadaImage)}
              style={style}
              src={ImgMissionTada}
              alt="미션 종"
            />
          )}
        />
      </div>
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <h2
            ref={ref}
            className={cn(css.title)}
            style={style}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: ImgMissionTitle }}
          />
        )}
      />
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <div ref={ref} className={cn(css.card)} style={style}>
            <h2 className={css.cardTitle}>
              {`To. ${bridgeData?.user.region.name3} ${bridgeData?.user.nickname}님`}
            </h2>
            <p className={css.cardContent}>
              환경의 날을 맞이해 당신 근처에서
              <br />
              <b>지구에 좋은 거래</b>를 해보세요.
            </p>
          </div>
        )}
      />
      <div className={css.guide}>
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <h2 ref={ref} className={cn(css.guideTitle)} style={style}>
              미션을 수행하는 3가지 방법
            </h2>
          )}
        />
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <p ref={ref} className={cn(css.guideSubtitle)} style={style}>
              내게 맞는 방법을 골라 미션을 수행해보세요
            </p>
          )}
        />
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <div ref={ref} className={cn(css.guideGrid)} style={style}>
              <div className={css.guideItem}>
                <img
                  src={ImgMissionGuide1}
                  className={css.guideItemImage}
                  alt="종이상자와 플러스 아이콘"
                />
                <div className={css.guideItemLabel}>판매 글 작성</div>
              </div>
              <div className={css.guideItem}>
                <img
                  src={ImgMissionGuide2}
                  className={css.guideItemImage}
                  alt="종이 쇼핑백"
                />
                <div className={css.guideItemLabel}>중고거래 후기 작성</div>
              </div>
              <div className={css.guideItem}>
                <img
                  src={ImgMissionGuide3}
                  className={css.guideItemImage}
                  alt="종이 상자와 하트 아이콘"
                />
                <div className={css.guideItemLabel}>나눔 글 작성</div>
              </div>
            </div>
          )}
        />
        {!isMissionCompleted && (
          <SimpleReveal
            render={({ ref, cn, style }) => (
              <button
                ref={ref}
                className={cn(css.missionButton)}
                style={style}
                type="button"
                onClick={() => {
                  localStorage.setItem(
                    LocalStorageKeys.HasStartMissionButtonClicked,
                    "true",
                  );
                  karrotBridge?.logEvent({
                    analytics: {
                      target: "FIREBASE",
                      name: "environment_day_2022_events",
                      params: JSON.stringify({
                        name: "click_start_mission_button",
                      }),
                    },
                  });

                  window.location.href = TAB_HOME_HREF;
                }}
              >
                당근거래 미션 수행하기
              </button>
            )}
          />
        )}
        {isMissionCompleted && (
          <SimpleReveal
            render={({ ref, cn, style }) => (
              <button
                ref={ref}
                className={cn(css.missionButtonDisabled)}
                style={style}
                type="button"
              >
                미션 완료!
              </button>
            )}
          />
        )}
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <div
              ref={ref}
              className={cn(css.missionButtonCaption)}
              style={style}
            >
              {missionStatus?.total_number_of_participants}명 이웃이 함께하고
              있어요
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Mission;
