import React from "react";
import { SimpleReveal } from "simple-reveal";

import ImgEventTitle from "!!raw-loader!../assets/img_event_title.svg";

import ImgEventConfetti from "../assets/img_event_confetti.svg";
import ImgEventReward1 from "../assets/img_event_reward_1.png";
import ImgEventReward2 from "../assets/img_event_reward_2.png";
import * as css from "./Event.css";

const Event: React.FC = () => (
  <div className={css.container}>
    <SimpleReveal
      initialTransform="scale(0.8)"
      render={({ ref, cn, style }) => (
        <img
          ref={ref}
          className={cn(css.confetti)}
          style={style}
          src={ImgEventConfetti}
          alt="콘페티"
        />
      )}
    />
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div ref={ref} className={cn(css.eventBadge)} style={style}>
          EVENT
        </div>
      )}
    />
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div
          ref={ref}
          className={cn(css.title)}
          style={style}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: ImgEventTitle }}
        />
      )}
    />
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div ref={ref} className={cn(css.eventRange)} style={style}>
          <b>이벤트 참여기간</b> 6.5 - 6.12
        </div>
      )}
    />
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div ref={ref} className={cn(css.rewardGrid)} style={style}>
          <div className={css.reward}>
            <img
              className={css.rewardImage}
              src={ImgEventReward1}
              alt="지구 모양 그림"
            />
            <div className={css.rewardTitle}>지구에 좋은 거래</div>
            <div className={css.rewardContent}>
              미션에 참여한 모두에게 프로필 활동 배지
            </div>
          </div>
          <div className={css.reward}>
            <img
              className={css.rewardImage}
              src={ImgEventReward2}
              alt="당근페이 3000원 상품권 그림"
            />
            <div className={css.rewardTitle}>당근페이 3천원</div>
            <div className={css.rewardContent}>
              추첨을 통해 300분에게 당근거래 지원금
            </div>
          </div>
        </div>
      )}
    />
  </div>
);

export default Event;
