import React, { createContext, useContext, useMemo } from "react";

import { useBridgeData } from "../bridge";
import { makeAjax } from "./makeAjax";

export const AjaxContext = createContext<ReturnType<typeof makeAjax> | null>(
  null,
);

interface AjaxProviderProps {
  endpoint: string;
  children: React.ReactNode;
}
export const AjaxProvider: React.FC<AjaxProviderProps> = ({
  endpoint,
  children,
}) => {
  const bridgeData = useBridgeData();

  const ajax = useMemo(
    () =>
      bridgeData
        ? makeAjax({
            endpoint,
            userAgent: bridgeData.app.userAgent,
            authToken: bridgeData.user.authToken,
          })
        : null,
    [bridgeData],
  );

  return <AjaxContext.Provider value={ajax}>{children}</AjaxContext.Provider>;
};

export function useAjax() {
  return useContext(AjaxContext);
}
