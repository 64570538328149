import React from "react";
import { SimpleReveal } from "simple-reveal";

import * as css from "./Disclaimer.css";

const Disclaimer: React.FC = () => (
  <div className={css.container}>
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <h2 ref={ref} className={cn(css.title)} style={style}>
          참여하기 전 확인해 주세요
        </h2>
      )}
    />
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div ref={ref} className={cn(css.section)} style={style}>
          <h3 className={css.sectionTitle}>이벤트 당첨자 발표</h3>
          <ul className={css.sectionList}>
            <li>6월 17일 (금)</li>
            <li>당첨자분들에게는 개별 푸시 알림을 드려요.</li>
          </ul>
        </div>
      )}
    />
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div ref={ref} className={cn(css.section)} style={style}>
          <h3 className={css.sectionTitle}>유의사항</h3>
          <ul className={css.sectionList}>
            <li>
              이 페이지는 모바일 환경에서 확인하시길 권장 드려요. PC에서 접근할
              경우 나의 당근거래 데이터를 조회할 수 없어요.
            </li>
            <li>이 캠페인은 6.5 - 6.12 동안 진행해요.</li>
            <li>
              캠페인 기간 동안 당근거래 미션을 수행하는 3가지 방법 중 1가지 이상
              참여하면 이벤트에 자동 참여돼요.
            </li>
            <li>
              참여해 주신 분들 중 300분을 추첨하여 당근거래 지원금을 드려요.
            </li>
            <li>
              당근거래 지원금 수령을 위해 미리 당근페이 가입을 해두시는 것을
              권장 드려요. 앱 하단의 ‘나의 당근’ 탭에서 당근페이에 가입할 수
              있어요.
            </li>
            <li>
              당근 거래 지원금에 당첨된 경우 당첨자 발표일 기준 7일 이내에
              당근페이에 가입하지 않으시면 당첨이 취소돼요.
            </li>
            <li>
              나의 당근거래 조회하기 결과는 2022년 6월 4일까지의 거래 내역을
              기준으로 산출했어요.
            </li>
          </ul>
        </div>
      )}
    />
  </div>
);

export default Disclaimer;
