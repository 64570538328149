import { useEffect, useState } from "react";

import { karrotBridge } from "./karrotBridge";

interface BridgeData {
  app: {
    userAgent: string;
  };
  user: {
    authToken: string;
    nickname: string;
    region: {
      name3: string;
    };
  };
}

let cachedBridgeData: BridgeData | null = (() => {
  if (
    process.env.GATSBY_TEST_USER_NICKNAME &&
    process.env.GATSBY_TEST_USER_AUTH_TOKEN &&
    process.env.GATSBY_TEST_APP_USER_AGENT &&
    process.env.GATSBY_TEST_USER_REGION_NAME3
  ) {
    return {
      app: {
        userAgent: process.env.GATSBY_TEST_APP_USER_AGENT,
      },
      user: {
        nickname: process.env.GATSBY_TEST_USER_NICKNAME,
        authToken: process.env.GATSBY_TEST_USER_AUTH_TOKEN,
        region: {
          name3: process.env.GATSBY_TEST_USER_REGION_NAME3,
        },
      },
    };
  }
  return null;
})();

export function useBridgeData() {
  const [value, setValue] = useState<BridgeData | null>(() => cachedBridgeData);

  useEffect(() => {
    async function getBridgeData(): Promise<BridgeData | null> {
      if (cachedBridgeData) {
        return cachedBridgeData;
      }

      const [userInfo, appInfo] = await Promise.all([
        karrotBridge?.getUserInfo({}),
        karrotBridge?.getAppInfo({}),
      ]);

      const user: any = userInfo?.info?.user;
      const app = appInfo?.info?.app;

      if (
        !app?.userAgent ||
        !user?.nickname ||
        !user?.authToken ||
        !user?.region?.name3
      ) {
        return null;
      }

      return {
        app: {
          userAgent: app.userAgent,
        },
        user: {
          nickname: user.nickname,
          authToken: user.authToken,
          region: {
            name3: user.region.name3,
          },
        },
      };
    }

    (async () => {
      const bridgeData = await getBridgeData();

      if (bridgeData) {
        setValue(bridgeData);
        cachedBridgeData = bridgeData;
      }
    })();
  }, []);

  return value;
}
