import React from "react";

import { AjaxProvider } from "../ajax";
import Cover from "../components/Cover";
import Disclaimer from "../components/Disclaimer";
import Event from "../components/Event";
import Mission from "../components/Mission";
import MyPurchases from "../components/MyPurchases";
import Share from "../components/Share";
import * as css from "./index.css";

const ENDPOINT =
  process.env.GATSBY_STAGE === "production"
    ? "https://www.daangn.com"
    : "https://webapp.alpha.kr.karrotmarket.com";

const PageIndex: React.FC = () => (
  <AjaxProvider endpoint={ENDPOINT}>
    <div className={css.container}>
      <div className={css.centered}>
        <Cover />
        <MyPurchases />
        <Mission />
        <Event />
        <Share />
        <Disclaimer />
      </div>
    </div>
  </AjaxProvider>
);

export default PageIndex;
