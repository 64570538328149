import React, { useEffect, useState } from "react";
import { SimpleReveal } from "simple-reveal";

import ImgMyPurchasesCardActivatedTitle from "!!raw-loader!../assets/img_my_purchases_card_activated_title.svg";
import ImgMyPurchasesCardTitle from "!!raw-loader!../assets/img_my_purchases_card_title.svg";

import { GetDealHistoryResponse, useAjax } from "../ajax";
import IconArrow20 from "../assets/ic_arrow_20.svg";
import { useBridgeData } from "../bridge";
import * as css from "./MyPurchases.css";

const MyPurchases: React.FC = () => {
  const ajax = useAjax();
  const bridgeData = useBridgeData();

  const [dealHistory, setDealHistory] = useState<GetDealHistoryResponse | null>(
    null,
  );

  return (
    <div className={css.container}>
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <h2 ref={ref} className={cn(css.title)} style={style}>
            {`${bridgeData?.user.nickname}님,\n당근거래가 지구에도 좋은 거래요!`}
          </h2>
        )}
      />
      <SimpleReveal
        render={({ ref, cn, style }) => (
          <p ref={ref} className={cn(css.subtitle)} style={style}>
            친환경적인 일상을 사는 게 어렵지만은 않아요. 우리는 당근거래를 통해
            이미 수많은 자원을 재사용하고 있거든요. 지구에도 좋은 나의 일상 속
            당근거래를 조회해보세요.
          </p>
        )}
      />
      {!dealHistory && (
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <div ref={ref} className={cn(css.card)} style={style}>
              <h2
                className={css.cardTitle}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: ImgMyPurchasesCardTitle }}
              />
              <button
                type="button"
                className={css.cardButton}
                onClick={async () => {
                  if (!ajax) {
                    return;
                  }

                  const [error, resp] = await ajax.getDealHistory();

                  if (error) {
                    return;
                  }

                  setDealHistory(resp);
                }}
              >
                나의 당근거래 조회하기
                <img
                  className={css.cardButtonArrow}
                  src={IconArrow20}
                  alt="아래로 화살표"
                />
              </button>
            </div>
          )}
        />
      )}
      {dealHistory && (
        <SimpleReveal
          render={({ ref, cn, style }) => (
            <div ref={ref} className={cn(css.cardActivated)} style={style}>
              <h2
                className={css.cardActivatedTitle}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: ImgMyPurchasesCardActivatedTitle,
                }}
              />
              {dealHistory.total_deal_count > 0 ? (
                <p className={css.cardActivatedContent}>
                  지금까지 {dealHistory.nickname}님은 {dealHistory.region_name}{" "}
                  이웃과 {dealHistory.total_deal_count}건의 당근 거래를
                  하셨네요. 특히 {dealHistory.category_name} 자원 재사용에
                  앞장섰어요. {dealHistory.nickname}님의 거래를 가치로 환산하면{" "}
                  {dealHistory.trees_text} 심은 것과 같아요.
                </p>
              ) : (
                <p className={css.cardActivatedContent}>
                  {dealHistory.region_name} 이웃들이 {dealHistory.nickname}님의
                  첫 거래를 기다리고 있어요! 당근이웃들이 함께 당근거래를 통해
                  만든 자원 순환의 가치는 연간 5천240만 그루의 소나무를 심은
                  것과 같아요. {dealHistory.region_name} 이웃들과 함께 지구에도
                  좋은 당근거래를 시작해보세요!
                </p>
              )}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default MyPurchases;
