import "src/styles/f.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/f.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61V7Y7aMBD8z1P4T6U76YzIUY5reBon3iQGx3ZtB7hWffeuHT4MCddUrYQExDOzu7O7zvyoVt22W5CfM0IqrTw9gKgbnxOlbcvkZvZrNu8x2RCzXiwSwOsV4MQPyEk2f11ZaDf4VAoFtDnRsvnb9QC8B0udYaVQdU7o8ksiuRxKZg80l4E4opelel//S4o3kqt7yfn7+oHkRMW3QZLjcsv1NL31VL23SXLv0wuemuG3oeSjNp9NvHDZ33Czc6oXevEZ3cPRUw6ltswLrWiQy0mnONjwM5EpPzFlugqPKlw4I9lHTioJx+QUbk+FitX1IEKYFLWiwkPrclKCQrsTbvUP3Hosqz+RmnHStnNeVB+0RKeQMkIUvZOIp1xYKINlCNOya1UC20YYPtY2lNOAFX7E7HCP3Vi8izxmDDDLVAlnACEFK3e11diX5Jm22KXrf8M4j/O72IxEvwSRMUjLbC1UxF5O2uSEFtp73eKs3M2kGgNltxidYrw2QxUzQNxJfO8t7KwLVRgt+k4Qgvd7sROeemZog7sjw/7QU7UebcM1tti3RMtGLaOd6F1nhcOO+dR5dwexILFF+xTiI6Rz4aYAib0ftK+LiEIfw5rFTvQtQpvSVdlHmN6DraQ+5KQRnIOK8xHSr/DddqoEc4Alf1q8kPB53kTL3I6KltU4HGcrLOOCSVqHb6z86dDg4L+QQuLQPCeBDzFwHMK4HyMDfoyQKBDvQ4xiLNCDZQZRvwFnGr06kQcAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "src/components/MyPurchases.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/MyPurchases.css.ts.vanilla.css\",\"source\":\"LndpejhzaDAgewogIG1hcmdpbjogM3JlbSAwOwp9Ci53aXo4c2gxIHsKICBtYXJnaW4tYm90dG9tOiAuNzVyZW07Cn0KLndpejhzaDIgewogIHBhZGRpbmc6IDAgMnJlbTsKICBjb2xvcjogdmFyKC0tc2VlZC1zY2FsZS1jb2xvci1ncmF5LTcwMCk7CiAgbWFyZ2luLWJvdHRvbTogMi41cmVtOwp9Ci53aXo4c2gzIHsKICBtYXJnaW46IDAgMS41cmVtOwogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXNlZWQtc2VtYW50aWMtY29sb3ItcGFwZXItY29udGVudHMpOwogIGJvcmRlci1yYWRpdXM6IC43NXJlbTsKICBwYWRkaW5nOiAycmVtOwp9Ci53aXo4c2g0IHsKICBmb250LXNpemU6IDEuMzc1cmVtOwogIGxpbmUtaGVpZ2h0OiAxNDQlOwogIGxldHRlci1zcGFjaW5nOiAtMiU7CiAgbWFyZ2luLWJvdHRvbTogMS4yNXJlbTsKfQoud2l6OHNoNSB7CiAgYmFja2dyb3VuZDogdmFyKC0tc2VlZC1zZW1hbnRpYy1jb2xvci1wcmltYXJ5KTsKICBjb2xvcjogdmFyKC0tc2VlZC1zZW1hbnRpYy1jb2xvci1vbi1wcmltYXJ5KTsKICB3aWR0aDogMTAwJTsKICBwYWRkaW5nOiAxcmVtOwogIGJvcmRlci1yYWRpdXM6IC43NXJlbTsKfQoud2l6OHNoNiB7CiAgbWFyZ2luLWxlZnQ6IC4xODc1cmVtOwp9Ci53aXo4c2g3IHsKICBtYXJnaW46IDAgMS41cmVtOwogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXNlZWQtc2NhbGUtY29sb3ItY2Fycm90LTUwMCk7CiAgYm9yZGVyLXJhZGl1czogLjc1cmVtOwogIHBhZGRpbmc6IDJyZW07Cn0KLndpejhzaDggewogIGNvbG9yOiB2YXIoLS1zZWVkLXN0YXRpYy1jb2xvci1ncmF5LTkwMCk7CiAgbWFyZ2luLWJvdHRvbTogMS4yNXJlbTsKfQoud2l6OHNoOSB7CiAgY29sb3I6IHZhcigtLXNlZWQtc3RhdGljLWNvbG9yLWdyYXktOTAwKTsKfQ==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var card = 'wiz8sh3';
export var cardActivated = 'wiz8sh7';
export var cardActivatedContent = 'wiz8sh9 xn5uju9 xn5ujuw xn5ujul';
export var cardActivatedTitle = 'wiz8sh8 xn5ujuw xn5ujul';
export var cardButton = 'wiz8sh5 xn5ujuh xn5ujug xn5uju6 xn5uju1 xn5ujuk xn5ujuq';
export var cardButtonArrow = 'wiz8sh6';
export var cardTitle = 'wiz8sh4 xn5ujul xn5ujuw xn5ujux';
export var container = 'wiz8sh0';
export var subtitle = 'wiz8sh2 xn5uju9 xn5ujuw xn5ujul';
export var title = 'wiz8sh1 xn5uju1 xn5uju3 xn5ujuw xn5ujux xn5ujul';