import to from "await-to-js";

export interface GetDealHistoryResponse {
  nickname: string;
  region_name: string;
  total_deal_count: 9;
  trees_count: 1;
  trees_text: string;
  category_name: string;
}

export interface GetMissionStatusResponse {
  user_mission_result: {
    has_written_sales_article: boolean;
    has_written_free_sales_article: boolean;
    has_written_review: boolean;
  };
  total_number_of_participants: number;
}

interface MakeAjaxOptions {
  endpoint: string;
  authToken: string;
  userAgent: string;
}
export function makeAjax({ endpoint, authToken, userAgent }: MakeAjaxOptions) {
  return {
    async getDealHistory() {
      return to(
        fetch(`${endpoint}/web/environment_day/user_deal_history.json`, {
          headers: {
            "X-Auth-Token": authToken,
            "X-User-Agent": userAgent,
          } as any,
        }).then((d) => d.json() as Promise<GetDealHistoryResponse>),
      );
    },
    async getMissionStatus() {
      return to(
        fetch(`${endpoint}/web/environment_day/mission_status.json`, {
          headers: {
            "X-Auth-Token": authToken,
            "X-User-Agent": userAgent,
          } as any,
        }).then((d) => d.json() as Promise<GetMissionStatusResponse>),
      );
    },
  };
}
