import React from "react";
import { SimpleReveal } from "simple-reveal";

import ImgShareTitle from "!!raw-loader!../assets/img_share_title.svg";

import IconShareLink from "../assets/ic_share_link.svg";
import { karrotBridge } from "../bridge";
import * as css from "./Share.css";

declare const Kakao: any;

const SHARE_URL = "https://daangn.onelink.me/oWdR/cp0vsu0s";

const Share: React.FC = () => (
  <div className={css.container}>
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div
          ref={ref}
          className={cn(css.title)}
          style={style}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: ImgShareTitle }}
        />
      )}
    />
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div ref={ref} className={cn(css.methodGrid)} style={style}>
          <button
            type="button"
            className={css.method}
            onClick={() => {
              const environment = karrotBridge?.driver.getCurrentEnvironment();

              switch (environment) {
                case "Android":
                case "Cupertino": {
                  karrotBridge?.share({
                    share: {
                      text: "혹시, 그거 아세요? 일상이 된 당근거래가 지구에 좋은 거래요!",
                      url: SHARE_URL,
                    },
                  });
                  break;
                }
                default: {
                  navigator.clipboard.writeText(SHARE_URL).then(() => {
                    // eslint-disable-next-line no-alert
                    window.alert("URL이 복사되었습니다");
                  });
                }
              }
            }}
          >
            <img
              className={css.methodImage}
              src={IconShareLink}
              alt="초록색 바탕에 URL이라고 써있는 그림"
            />
            <div className={css.methodTitle}>링크 공유</div>
          </button>
        </div>
      )}
    />
  </div>
);

export default Share;
