import "src/styles/f.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/f.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61V7Y7aMBD8z1P4T6U76YzIUY5reBon3iQGx3ZtB7hWffeuHT4MCddUrYQExDOzu7O7zvyoVt22W5CfM0IqrTw9gKgbnxOlbcvkZvZrNu8x2RCzXiwSwOsV4MQPyEk2f11ZaDf4VAoFtDnRsvnb9QC8B0udYaVQdU7o8ksiuRxKZg80l4E4opelel//S4o3kqt7yfn7+oHkRMW3QZLjcsv1NL31VL23SXLv0wuemuG3oeSjNp9NvHDZ33Czc6oXevEZ3cPRUw6ltswLrWiQy0mnONjwM5EpPzFlugqPKlw4I9lHTioJx+QUbk+FitX1IEKYFLWiwkPrclKCQrsTbvUP3Hosqz+RmnHStnNeVB+0RKeQMkIUvZOIp1xYKINlCNOya1UC20YYPtY2lNOAFX7E7HCP3Vi8izxmDDDLVAlnACEFK3e11diX5Jm22KXrf8M4j/O72IxEvwSRMUjLbC1UxF5O2uSEFtp73eKs3M2kGgNltxidYrw2QxUzQNxJfO8t7KwLVRgt+k4Qgvd7sROeemZog7sjw/7QU7UebcM1tti3RMtGLaOd6F1nhcOO+dR5dwexILFF+xTiI6Rz4aYAib0ftK+LiEIfw5rFTvQtQpvSVdlHmN6DraQ+5KQRnIOK8xHSr/DddqoEc4Alf1q8kPB53kTL3I6KltU4HGcrLOOCSVqHb6z86dDg4L+QQuLQPCeBDzFwHMK4HyMDfoyQKBDvQ4xiLNCDZQZRvwFnGr06kQcAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "src/components/Cover.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/Cover.css.ts.vanilla.css\",\"source\":\"Ll8xNnZhZzNiMCB7CiAgaGVpZ2h0OiA0NXJlbTsKICB3aWR0aDogMjQuMzc1cmVtOwp9Ci5fMTZ2YWczYjEgewogIHdpZHRoOiAxMDAlOwp9Ci5fMTZ2YWczYjIgewogIGZvbnQtc2l6ZTogMS4yNXJlbTsKICBmb250LXdlaWdodDogNjAwOwogIGJhY2tncm91bmQtY29sb3I6ICMwMEE4MkI7CiAgY29sb3I6IHZhcigtLXNlZWQtc3RhdGljLWNvbG9yLXdoaXRlKTsKICBwYWRkaW5nOiAuMTg3NXJlbSAxLjEyNXJlbTsKICBib3JkZXItcmFkaXVzOiAxcmVtOwogIHRvcDogMi41cmVtOwp9Ci5fMTZ2YWczYjMgewogIHRvcDogMTEuMzEyNXJlbTsKICBsZWZ0OiA1LjI1cmVtOwogIGNvbG9yOiB2YXIoLS1zZWVkLXNlbWFudGljLWNvbG9yLXBhcGVyLWRlZmF1bHQpOwogIHotaW5kZXg6IDE7Cn0KLl8xNnZhZzNiNCB7CiAgdG9wOiAzNS42ODc1cmVtOwogIGxlZnQ6IDIuMDYyNXJlbTsKICBjb2xvcjogdmFyKC0tc2VlZC1zZW1hbnRpYy1jb2xvci1wYXBlci1kZWZhdWx0KTsKICB6LWluZGV4OiAxOwp9Ci5fMTZ2YWczYjUgewogIHRvcDogMzUuMTI1cmVtOwogIGxlZnQ6IDEyLjc1cmVtOwogIGNvbG9yOiB2YXIoLS1zZWVkLXNlbWFudGljLWNvbG9yLXBhcGVyLWRlZmF1bHQpOwogIHotaW5kZXg6IDE7Cn0KLl8xNnZhZzNiNiB7CiAgdG9wOiA0LjA2MjVyZW07CiAgbGVmdDogMi4wNjI1cmVtOwogIHotaW5kZXg6IDE7Cn0KLl8xNnZhZzNiNyB7CiAgdG9wOiA3LjEyNXJlbTsKICBsZWZ0OiA0LjQzNzVyZW07CiAgY29sb3I6IHZhcigtLXNlZWQtc2NhbGUtY29sb3ItZ3JheS05MDApOwp9Ci5fMTZ2YWczYjggewogIHRvcDogMjUuNjg3NXJlbTsKICBsZWZ0OiAyLjA2MjVyZW07CiAgY29sb3I6IHZhcigtLXNlZWQtc2NhbGUtY29sb3ItZ3JheS05MDApOwp9\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var badge = '_16vag3b2 xn5ujur xn5ujue';
export var confettiImage = '_16vag3b6 xn5ujur';
export var container = '_16vag3b0 xn5ujus xn5ujug xn5ujui';
export var earthImage = '_16vag3b3 xn5ujur';
export var heartImage = '_16vag3b4 xn5ujur';
export var img = '_16vag3b1';
export var leafImage = '_16vag3b5 xn5ujur';
export var title1 = '_16vag3b7 xn5ujur';
export var title2 = '_16vag3b8 xn5ujur';