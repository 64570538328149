import React from "react";
import { SimpleReveal } from "simple-reveal";

import ImgCoverEarth from "!!raw-loader!../assets/img_cover_earth.svg";
import ImgCoverHeart from "!!raw-loader!../assets/img_cover_heart.svg";
import ImgCoverLeaf from "!!raw-loader!../assets/img_cover_leaf.svg";
import ImgCoverTitle1 from "!!raw-loader!../assets/img_cover_title_1.svg";
import ImgCoverTitle2 from "!!raw-loader!../assets/img_cover_title_2.svg";

import ImgCoverConfetti from "../assets/img_cover_confetti.svg";
import * as css from "./Cover.css";

const Cover: React.FC = () => (
  <div className={css.container}>
    <SimpleReveal
      render={({ ref, cn, style }) => (
        <div ref={ref} className={cn(css.badge)} style={style}>
          환경의 날
        </div>
      )}
    />
    <SimpleReveal
      delay={100}
      render={({ ref, cn, style }) => (
        <div
          ref={ref}
          className={cn(css.title1)}
          style={style}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: ImgCoverTitle1 }}
        />
      )}
    />
    <SimpleReveal
      delay={200}
      render={({ ref, cn, style }) => (
        <div
          ref={ref}
          className={cn(css.earthImage)}
          style={style}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: ImgCoverEarth,
          }}
        />
      )}
    />
    <SimpleReveal
      delay={300}
      render={({ ref, cn, style }) => (
        <div
          ref={ref}
          className={cn(css.title2)}
          style={style}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: ImgCoverTitle2 }}
        />
      )}
    />
    <SimpleReveal
      delay={400}
      render={({ ref, cn, style }) => (
        <div
          ref={ref}
          className={cn(css.heartImage)}
          style={style}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: ImgCoverHeart,
          }}
        />
      )}
    />
    <SimpleReveal
      delay={500}
      render={({ ref, cn, style }) => (
        <div
          ref={ref}
          className={cn(css.leafImage)}
          style={style}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: ImgCoverLeaf,
          }}
        />
      )}
    />
    <SimpleReveal
      delay={600}
      initialTransform="scale(0.8)"
      render={({ ref, cn, style }) => (
        <img
          ref={ref}
          className={cn(css.confettiImage)}
          style={style}
          src={ImgCoverConfetti}
          alt="콘페티"
        />
      )}
    />
  </div>
);

export default Cover;
